module.exports = {
  lang: "English",
  home: "Home",
  AboutIPO: "About",
  SubmitIPO: "PLO",
  Submit: "Submit",
  Login: "Login",
  Logout: "Logout",
  Start: "Start",
  Ending: "Ending",
  End: "End",
  Search: "Search",
  Project: "Project",
  Token: "Token",
  Locked: "Locked",
  LockedCap: "Locked/Cap",
  Raised: "Raised",
  RaisedCap: "Raised/Cap",
  Leases: "Leases",
  Contribute: "Contribute",
  Title: "Title",
  ParaID: "ParaID",
  Owner: "Owner",
  Rewards: "Rewards",
  TokenSymbol: "Token Symbol",
  RewardsInformation: "Rewards Information",
  RewardsInformationURL: "Rewards Information Public Announcement URL",
  Records: "Records",
  ExtrinsicHash: "Extrinsic Hash",
  Amount: "Amount",
  DateTime: "Date Time",
  More: "More",
  Withdraw: "Withdraw",
  noMathExtension: "Please connect Math Wallet first",
  noAddress: "Please switch to %W wallet operation",
  SelectAccount: "Select Account",
  noAccount: "No account",
  ViewDetails: "View Details",
  NoInfo: "No Information",
  backToHome: "Back To Home",
  EnterAmountFirst: "Enter Amount First",
  noOwner: "Enter Owner First",
  noParaID:"Enter ParaID First",
  noTokenSymbol:"Enter Token Symbol First",
  noRewardsInformation:"Enter Rewards Information First",
  noRewardsInformationURL: "Enter Rewards Information Public Announcement URL First",
  APR: "APR",
  SlotAuction: "Slot Auction",
  Auction: "Auction",
  LogInMathWallet: "Log In with MathWallet Extension",
  LogInPolkadotJS: "Log In with PolkadotJS Extension",
  SelectLoginAccount: "Please select a wallet to login",
  days: " days ",
  day: " day ",
  hrs: " hrs ",
  hr: " hr ",
  mins: " mins ",
  min: " min ",
  s: " s ",
  amountNotenough: "Have no enough available balance",
  available: "Available Balance",
  Rewards_tip1: "Rewards Information Public Announcement",
  Rewards_tip2: "More Project Information",
}